import React from 'react';
import MemberItem from './MemberItem';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid2';

export type TeamData = {
  id: number;
  name: string;
  position: string;
  description: string;
  srcImg: string;
  linkedin: string;
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 2,

  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type TeamProps = {
  team: TeamData[];
};

const Team = ({ team }: TeamProps) => {
  const { messages } = useIntl();
  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Grid container spacing={0}>
        {team.map((member) => {
          return (
            <Grid key={member.id} size={3} sx={{ px: 2.5 }} alignItems='stretch'>
              <MemberItem member={member} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Team;
