import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react';
import Button from '@mui/material/Button';

const HeroBtnGroup = () => {
  return (
    <>
      <div className='d-sm-flex align-items-center flex-column'>
        <div className='dropdown download-btn  mb-25 me-4 text-center'>
          <Button className='tran3s fs-24 fw-bold' type='link' href='/signup'>
            <ArrowForwardIcon sx={{ fontSize: '2rem' }} /> Try free
          </Button>
        </div>
      </div>
    </>
  );
};

export default HeroBtnGroup;
